var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"col-12"},[_c('Breadcrumb',{attrs:{"items":_vm.items}}),_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-2"},[_c('div',[(_vm.loadingUser)?_c('b-skeleton',{staticClass:"mt-1",attrs:{"type":"text","width":"35%"}}):_c('h2',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.user.name))]),(_vm.loadingPos)?_c('b-skeleton',{staticClass:"mt-2",attrs:{"type":"text","width":"25%"}}):_c('h4',{staticClass:"mb-0 text-muted"},[_vm._v(_vm._s(_vm.pointOfSale.name))])],1),_c('b-button',{staticClass:"btn btn-pdv-gradient-blue-transparent",attrs:{"size":"sm"},on:{"click":_vm.goToBack}},[_c('i',{staticClass:"fe-corner-up-left"}),_vm._v(" "+_vm._s(_vm.i18nBack)+" ")])],1),_c('PageHeader',{attrs:{"title":`${_vm.i18nAnswer} ${_vm.i18nWorkSchedule}`}}),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleOk.apply(null, arguments)}}},[_c('b-form-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"required label-pdv",attrs:{"label":_vm.i18nBusinessUnit,"label-for":"business-unit","invalid-feedback":_vm.getI18n(
                                        'ERROR_CODES.invalid_business_unit'
                                    )}},[_c('multiSelectWithService',{ref:"parentBusinessUnitSelect",attrs:{"id":'business-unit',"service":'business-units',"label":"name","searchable":true,"multiple":false,"parameters":_vm.filterParameters},model:{value:(_vm.data.businessUnit),callback:function ($$v) {_vm.$set(_vm.data, "businessUnit", $$v)},expression:"data.businessUnit"}})],1)],1)],1),(_vm.data.businessUnit)?_c('b-form-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"required label-pdv",attrs:{"label":_vm.i18nQuestionnaire,"label-for":"questionnaire","invalid-feedback":_vm.getI18n(
                                                'ERROR_CODES.invalid_business_unit'
                                            )}},[_c('multiSelectWithService',{ref:"questionnaireMultiselect",attrs:{"id":'questionnaire',"service":'questionnaires',"searchable":true,"multiple":false,"parameters":_vm.questionnaireFilterParameters},model:{value:(_vm.data.questionnaire),callback:function ($$v) {_vm.$set(_vm.data, "questionnaire", $$v)},expression:"data.questionnaire"}})],1)],1)],1):_vm._e(),(_vm.data.businessUnit && _vm.data.questionnaire?.type === 'product')?_c('b-form-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"required label-pdv",attrs:{"label":_vm.i18nMixes,"label-for":"mixes"}},[_c('multiSelectWithService',{ref:"mixesMultiselect",attrs:{"id":'mixes',"service":'mixes',"searchable":true,"multiple":false,"parameters":_vm.filterMixesParameters},model:{value:(_vm.data.mixes),callback:function ($$v) {_vm.$set(_vm.data, "mixes", $$v)},expression:"data.mixes"}})],1)],1)],1):_vm._e(),(_vm.data.questionnaire?.type === 'product')?_c('b-form-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"required label-pdv",attrs:{"label":_vm.i18nProduct,"label-for":"productCategory","invalid-feedback":_vm.getI18n(
                                        'ERROR_CODES.invalid_business_unit'
                                    )}},[_c('multiSelectWithService',{ref:"productCategoryMultiselect",attrs:{"id":'productCategory',"service":'products',"searchable":true,"multiple":false},model:{value:(_vm.data.product),callback:function ($$v) {_vm.$set(_vm.data, "product", $$v)},expression:"data.product"}})],1)],1)],1):_vm._e(),(_vm.data.questionnaire)?_c('b-form-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"required label-pdv",attrs:{"label":'Questão',"label-for":"question","invalid-feedback":_vm.getI18n(
                                        'ERROR_CODES.invalid_business_unit'
                                    )}},[_c('Multiselect',{attrs:{"id":'question',"options":_vm.questions,"loading":_vm.questionsLoading,"track-by":"id","label":"label"},model:{value:(_vm.data.question),callback:function ($$v) {_vm.$set(_vm.data, "question", $$v)},expression:"data.question"}})],1)],1)],1):_vm._e(),(_vm.data.question)?_c('b-form-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"w-100",attrs:{"label-for":"logoFile","label":_vm.i18nPhoto,"invalid-feedback":_vm.fileError}},[_c('div',{staticClass:"image-preview-container"},[_c('label',{staticClass:"custom-file-upload",attrs:{"for":"logoFile"}},[_c('i',{staticClass:"fe-upload-cloud"}),_c('span',[_vm._v("Selecionar arquivos")])]),_c('input',{ref:"logoFile",staticClass:"d-none",attrs:{"id":"logoFile","type":"file","multiple":"","accept":".jpg"},on:{"change":_vm.onFileChange}}),_vm._l((_vm.data.photos),function(image,index){return _c('div',{key:index,staticClass:"image-preview"},[_c('img',{staticClass:"img-thumbnail",attrs:{"src":image.url,"alt":"Miniatura da imagem"}}),_c('button',{staticClass:"btn btn-danger btn-sm image-remove",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeImage(index)}}},[_vm._v(" X ")])])})],2)])],1)],1):_vm._e()],1)],1)],1),_c('save-button',{ref:"okButton",attrs:{"busy":_vm.submitLoading,"disabled":_vm.submitLoading || !_vm.dataValid,"title":_vm.getI18n('ACTION.save')},on:{"onClick":_vm.handleOk}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }